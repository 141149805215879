<template>
    <div v-for="block in blocks" :key="block.id" class="wrapper">
        <div v-if="block.type=='text'" v-html="block.text"></div>
        <div v-if="block.type=='films'" class="film-wrapper">
            <h2 class="title">{{block.title}}</h2>
            <div v-for="film in block.films" :key="film.id">
                <router-link :to="{ name: 'Film', params: { slug: film.slug } }" class="project-preview h2">{{film.title}}</router-link>
            </div>
        </div>
        <div v-if="block.type=='textTwoColumn'" class="textTwoColumn">
            <div class="left small" v-html="block.textLeft"></div>
            <div class="right" v-html="block.textRight"></div>
        </div>
    </div>
</template>


<script>

export default {
    name: 'PageContentComponent',
    props: {
        blocks: {
            type: Array,
            default: function() {return [];}
        }
    },
}
</script>
