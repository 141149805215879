<template>
    <transition name="fade">
        <div class="about" v-if="loaded">
            <div class="wrapper-text">
                <page-content :blocks="content"></page-content>
                <!-- <div class="textTwoColumn">
                    <div class="left small">
                        <p>Das ist ein Test</p>
                    </div>

                    <div class="right">
                        <p>Das ist ein Test 2</p>
                    </div>
                </div> -->
            </div>
        </div>
    </transition>
</template>

<script>

import PageContent from '@/components/PageContentComponent.vue';

export default {
    name: 'About',
    components: {
        PageContent
    },
    props: {},
    data () {
        return {
            loaded: false
        };
    },
    computed: {
        // a computed getter
        content() {
            let page = this.$store.getters.getPageBySlug('about-1');
            if (page)
                return page.blocks;
            else
                return [];
        }
    },
    mounted(){
        this.loaded = true;
    },
}
</script>
